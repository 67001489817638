<template>
  <div class="right">
    <span class="spen">账户设置</span>
    <Loading v-if="isLoading" />
    <div class="daohang">
      <ul class="ul">
        <router-link :to="{ name: 'User' }" tag="li">个人基本信息</router-link>
        <router-link :to="{ name: 'Safe' }" tag="li">账户安全</router-link>
        <router-link :to="{ name: 'Bind' }" tag="li">账号绑定</router-link>
        <router-link :to="{ name: 'Address' }" tag="li">收货地址</router-link>
      </ul>
    </div>
    <div class="tit">
      <div class="tit-left">
        <img src="../../../assets/order/safe.png" alt="" />
      </div>
      <div class="tit-right">
        <div class="tit-right-t">安全等级：{{ dengji }}</div>
        <div class="tit-right-b">{{ daren }}</div>
      </div>
    </div>
    <div class="conter">
      <div class="list">
        <div><img src="../../../assets/order/safe1.png" alt="" /></div>
        <div class="top">手机号</div>
        <div class="center">{{ phone }}</div>
        <div class="bottom" @click="change">修改手机号</div>
        <div class="img">
          <img src="../../../assets/order/you2.png" alt="" />
        </div>
      </div>
      <div class="list list1">
        <div><img src="../../../assets/order/safe2.png" alt="" /></div>
        <div class="top">登录密码</div>
        <div class="center">定期更换密码有助于账号安全</div>
        <div class="bottom" @click="password">修改登录密码</div>
        <div class="img">
          <img src="../../../assets/order/you2.png" alt="" />
        </div>
      </div>
      <div class="list list2">
        <div><img src="../../../assets/order/safe3.png" alt="" /></div>
        <div class="top">支付密码</div>
        <div class="center">支付所购买商品或服务时使用</div>
        <div class="bottom" @click="paypass">设置支付密码</div>
        <div class="img">
          <img v-if="password1" src="../../../assets/order/you2.png" alt="" />
          <img v-else src="../../../assets/order/you1.png" alt="" />
        </div>
      </div>
      <div class="list list3">
        <div><img src="../../../assets/order/safe4.png" alt="" /></div>
        <div class="top">邮箱</div>
        <div class="center">{{ youxiang }}</div>
        <div class="bottom" @click="mail">绑定邮箱</div>
        <div class="img">
          <img v-if="email" src="../../../assets/order/you2.png" alt="" />
          <img v-else src="../../../assets/order/you1.png" alt="" />
        </div>
      </div>
      <div class="list list4">
        <div><img src="../../../assets/order/safe5.png" alt="" /></div>
        <div class="top">实名认证</div>
        <div class="center">{{ name }} {{ idcard }}</div>
        <div class="bottom" @click="autonym">查看实名信息</div>
        <div class="img">
          <img v-if="idcard1" src="../../../assets/order/you2.png" alt="" />
          <img v-else src="../../../assets/order/you1.png" alt="" />
        </div>
      </div>
      <div class="list list5">
        <div><img src="../../../assets/order/safe.png" alt="" /></div>
        <div class="top">注销账户</div>
        <div class="center">{{ phone }}</div>
        <div class="bottom" @click="cancelAccount">注销账户</div>
        <div class="img">
          <img src="../../../assets/order/you2.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import { setCookie, getCookie, delCookie } from '@/utils/cookie';
export default {
  data() {
    return {
      phone: '',
      isLoading: true,
      idcard: '',
      name: '',
      email: true,
      idcard1: true,
      password1: true,
      dengji: '',
      daren: '您与安全达人仅一步之遥',
      youxiang: '',
    };
  },
  created() {
    this.getorder();
    this.zhifu();
    this.getbindingnum();
  },
  methods: {
    getorder() {
      get('api/member/selMemberInfo').then((res) => {
        this.isLoading = false;
        this.youxiang = res.data.data.email;
        this.phone = res.data.data.phone.replace(
          res.data.data.phone.substr(3, 4),
          '****'
        );
        this.name = this.nameFormat(res.data.data.realName);
        this.idcard = this.formatIDcard(res.data.data.idCard);
        if (res.data.data.email) {
          this.email = true;
        } else {
          this.email = false;
        }
        if (res.data.data.idCard) {
          this.idcard1 = true;
        } else {
          this.idcard1 = false;
        }
      });
    },
    zhifu() {
      get('api/account/getisaccountpay').then((res) => {
        if (res.data.msg == '1') {
          this.password1 = true;
        } else {
          this.password1 = false;
        }
      });
    },
    getbindingnum() {
      get('api/member/getbindingnum').then((res) => {
        if (res.data.data == 2) {
          this.dengji = '低';
        } else if (res.data.daata == 3 || res.data.data == 4) {
          this.dengji = '中';
        } else if (res.data.data == 5) {
          this.dengji = '高';
          this.daren = '您已是安全达人';
        } else {
          this.dengji = '未知';
        }
      });
    },
    //将姓名进行隐藏处理
    nameFormat(item) {
      if (!item) return '';
      let str = item;
      if (str.length == 2) {
        str = str
          .toString()
          .replace(/^([^\x00-\xff])([^\x00-\xff]{0,})([^\x00-\xff])/g, '$1*');
      } else if (str.length == 3) {
        str = str
          .toString()
          .replace(/^([^\x00-\xff])([^\x00-\xff]{0,})([^\x00-\xff])/g, '$1*$3');
      } else if (str.length == 4) {
        str = str
          .toString()
          .replace(
            /^([^\x00-\xff])([^\x00-\xff]{0,2})([^\x00-\xff])/g,
            '$1**$3'
          );
      } else if (str.length > 4) {
        str = str
          .toString()
          .replace(
            /^([^\x00-\xff])([^\x00-\xff]{0,3})([^\x00-\xff])/g,
            '$1***$3'
          );
      } else {
      }
      // str = str.toString().replace(/^([^\x00-\xff])([^\x00-\xff]{1,3})([^\x00-\xff])/g , '$1**$3')
      return str;
    },
    //将身份证号进行隐藏处理
    formatIDcard(value) {
      if (!value) return '';
      let str = value;
      str = str.toString().replace(/^(.{6})(?:\w+)(.{4})$/, '$1********$2');
      return str;
    },
    password() {
      this.$router.push({
        name: 'Changepassword',
      });
    },
    change() {
      this.$router.push({
        name: 'Select',
      });
    },
    paypass() {
      this.$router.push({
        name: 'Paypass',
      });
    },
    mail() {
      this.$router.push({
        name: 'Mail',
      });
    },
    autonym() {
      this.$message.warning({
        message: '功能开发中',
      });
      // this.$router.push({
      //   name: 'Autonym',
      // });
    },
    cancelAccount(){
      this.$confirm('是否注销此账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        post('api/users/cancelAccount').then((res) => {
          delCookie('username');
          delCookie('memberId');
          delCookie('Authorization');
          this.$router.go(0);
        })
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .daohang {
    font-size: 15px;
    color: #272727;
    margin-right: 25px;
    height: 60px;

    .ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      width: 400px;
      li {
        list-style: none;
        cursor: pointer;
        &.router-link-exact-active.router-link-active {
          color: #3661fe;
        }
      }
    }
  }
  .tit {
    width: 978px;
    height: 94px;
    display: flex;
    background: url('../../../assets/order/safeback.jpg') no-repeat center
      center;
    &-left {
      width: 42px;
      height: 52px;
      margin-left: 20px;
      margin-top: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-right {
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-t {
        font-size: 16px;
        color: #333;
      }
      &-b {
        color: #555;
        margin-top: 10px;
      }
    }
  }
  .conter {
    width: 978px;
    display: flex;
    flex-wrap: wrap;
    .list {
      width: 313px;
      height: 174px;
      background: #dce8f4;
      position: relative;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 60px 0;
      .img {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      .top {
        font-size: 18px;
        color: #343434;
      }
      .center {
        color: #6b6f70;
        transform: scale(1);
      }
      .bottom {
        width: 163px;
        height: 31px;
        text-align: center;
        line-height: 31px;
        border: 2px solid #3f6efc;
        color: #1851ff;
        border-radius: 25px;
        cursor: pointer;
      }
    }
    .list2,
    .list1,
    .list4 {
      margin-left: 19px;
    }
    .list5 {
      margin-left: 19px;
    }
    .list1 {
      background: #f9ebe0;
    }
    .list2 {
      background: #e9f5eb;
    }
    /*.list3 {*/
    /*  background: #efe4ec;*/
    /*}*/
    .list4 {
      background: #f9ebe0;
    }
    .list5 {
      background: #e9f5eb;
    }
  }
}
</style>
